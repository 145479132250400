import React, { Component } from 'react';
import Style from './Style';
const clientId = window.localStorage.getItem('jet7-client-id');

class Privacy extends Component{
    render() {
        return (
            <Style>
                <div className="privacy-container">
                    <h1>Política de Privacidade</h1>
                    <br/>
                    <p>A Balto Soluções de Tecnologia Ltda ("Balto") tem o compromisso com a transparência, a privacidade e a segurança dos dados de seus clientes. Por esse motivo, criou esta política de privacidade para explicar como coletamos e tratamos as informações coletadas.</p>
                    <p>A Política de Privacidade, detalhada a seguir, demonstra como os seus dados pessoais são tratados, quais informações coletamos e como as utilizamos, armazenamos ou excluímos, em linha com as disposições da Lei Geral de Proteção de Dados (Lei 13.709/18).</p>
                    <p>Ressaltamos que não é possível oferecer as funcionalidades da plataforma e os nossos serviços sem ter acesso aos dados coletados. Não coletamos mais dados do que os estritamente necessários para a execução dessas atividades. Caso o titular dos dados não esteja de acordo com as disposições desta Política de Privacidade, o titular deverá descontinuar o uso dos nossos serviços e/ou o seu acesso ou uso de nosso site.</p>

                    <br/>


                    <h2>1. Dados Coletados</h2>
                    <p>A Balto coleta os seguintes tipos de dados:</p>
                    <ul>
                        <li><strong>Dados de Identificação:</strong> Nome, CPF, telefone, e-mail, endereço, gênero, data de nascimento.</li>
                        <br/>
                        <li><strong>Dados de Atendimento:</strong> Áudios captados durante interações entre atendentes de farmácias e clientes.</li>
                        <br/>
                        <li><strong>Dados de Preferências e Comportamento:</strong> Histórico de interação, preferências de consumo e padrões de comportamento dos clientes.</li>
                        <br/>
                        <li><strong>Dados Anonimizados:</strong> Informações tratadas de forma a não permitir a identificação do titular dos dados, utilizadas para análises de mercado e estratégias de negócios.</li>
                    </ul>

                    <br/>

                    <h2>2. Finalidade do Tratamento de Dados</h2>
                    <p>A coleta e o tratamento de dados são realizados para:</p>
                    <ul>
                        <li>Melhorar a experiência de consumo dos clientes, com recomendações personalizadas de produtos e serviços mais compatíveis com as suas preferências,</li>
                        <br/>
                        <li>Atendimento personalizado na farmácia em que você está fazendo sua compra, com o oferecimento de sugestões de produtos com base no seu histórico de compras e nas suas necessidades individuais.</li>
                        <br/>
                        <li>Aprimorar os atendimentos em farmácias, otimizando as interações com os balconistas.</li>
                        <br/>
                        <li>Fornecer inteligência de mercado para farmácias e indústrias farmacêuticas, como análises de preferências, percepção de marcas e tendências de consumo.</li>
                        <br/>
                        <li>Permitir a comercialização de dados anonimizados para fins de pesquisa e estratégias de mercado.</li>
                        <br/>
                        <li>Cumprir obrigações legais e regulatórias.</li>
                    </ul>

                    <br/>

                    <h2>3. Compartilhamento de Dados</h2>
                    <p>A Balto pode compartilhar dados com:</p>
                    <ul>
                        <li><strong>Farmácias Parceiras:</strong> Para aprimorar a relação com seus clientes e personalizar o atendimento.</li>
                        <br/>
                        <li><strong>Indústrias Farmacêuticas:</strong> Para fornecer insights sobre tendências de consumo e percepção de produtos.</li>
                        <br/>
                        <li><strong>Autoridades Legais:</strong> Quando exigido por lei ou para cumprir obrigações legais.</li>
                        <br/>
                        <li><strong>Parceiros de Tecnologia:</strong> Para armazenamento, segurança da informação e suporte técnico.</li>
                        <br/>
                    </ul>
                    <p><strong>O compartilhamento de dados identificados ocorre apenas com consentimento do titular.</strong> Dados anonimizados podem ser utilizados para fins comerciais e estatísticos sem necessidade de autorização individual.</p>

                    <br/>

                    <h2>4. Armazenamento e Proteção dos Dados</h2>
                    <p>A Balto adota medidas técnicas e organizacionais para garantir a segurança das informações, incluindo:</p>
                    <ul>
                        <li>Criptografia e controle de acesso.</li>
                        <br/>
                        <li>Políticas internas de segurança da informação.</li>
                        <br/>
                        <li>Acesso restrito aos dados somente para colaboradores autorizados.</li>
                        <br/>
                        <li>Armazenamento dos dados pelo tempo necessário para cumprir as finalidades informadas.</li>
                    </ul>

                    <br/>

                    <h2>5. Direitos dos Titulares de Dados</h2>
                    <p>Os titulares de dados podem, a qualquer momento, exercer seus direitos garantidos pela LGPD, incluindo:</p>
                    <ul>
                        <li>Confirmação da existência de tratamento de seus dados.</li>
                        <br/>
                        <li>Acesso e correção de dados incompletos, inexatos ou desatualizados.</li>
                        <br/>
                        <li>Solicitação de anonimização, bloqueio ou eliminação de dados.</li>
                        <br/>
                        <li>Revogação de consentimento quando aplicável.</li>
                    </ul>

                    <p>Para exercê-los, entre em contato com o Encarregado de Proteção de Dados da Balto pelo e-mail: <a className="dpo-contact" href="mailto:dpo@baltohealth.com" title="">dpo@baltohealth.com</a>.</p>

                    <br/>

                    <h2>6. Atualização da Política</h2>
                    <p>A Balto pode atualizar esta Política de Privacidade periodicamente. Qualquer alteração substancial será comunicada por meio dos nossos canais oficiais.</p>

                    <br/>

                    <h2>7. Contato</h2>
                    <p className='p-without-margin'>Para dúvidas ou solicitações, entre em contato com nosso Encarregado de Proteção de Dados.</p>
                    <p>E-mail: <a className="dpo-contact" href="mailto:dpo@baltohealth.com" title="">dpo@baltohealth.com</a></p>
                    <br/>
                    <p className='p-with-border-top'>Esta Política de Privacidade entra em vigor na data de sua publicação e permanecerá vigente até futuras revisões.</p>
                    <p><strong>Data da última atualização:</strong> [28/02/2025]</p>

                    <br/>

                    <p className='p-without-margin'>Balto Soluções de Tecnologia Ltda</p>
                    <p className='p-without-margin'>CNPJ: 48.554.329/0001-20</p>
                    <p>Endereço: Rua Professor Oliveira Fausto, Nº 90, Casa 08, CEP 03184-050, Bairro Alto da Mooca, São Paulo - SP</p>
                </div>
            </Style>
        )
    }
}

export default Privacy;