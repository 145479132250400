import React, { Component } from 'react';
import Style from './Style';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import BALTObranco from '../../BALTObranco.png';
import { postOnDataLake } from "../../helpers/postOnDataLake.js";

class WebFooter extends Component {
    constructor(props) {
        super(props);
        this.thisPostOnDataLake = this.thisPostOnDataLake.bind(this);
    }

    thisPostOnDataLake(eventName) {
        // postOnDataLake(eventName, window.location.href, {});
    }

    render() {
        return (
            <Style className="col-12">
                <div className="main-container">
                    <div className="first-row">
                        <h3>Faça parte da nova forma de fazer saúde</h3>
                    </div>
                    <div className="second-row">
                        <div className="second-inside-row">
                            <AnchorLink offset='78' href='#contact' className="simple-link" onClick={() => this.thisPostOnDataLake("Web - Clicou em Contato")}>
                                <span onClick={() => this.thisPostOnDataLake("Web - Clicou em Termos de Uso")}>
                                    <div>Contato</div>
                                </span>
                            </AnchorLink>
                            <a className="simple-link" href="/politica-de-privacidade">
                                <div>Politica de Privacidade</div>
                            </a>
                        </div>
                    </div>
                    <div className="third-row">
                        <img className="balto-icon" src={BALTObranco} />
                        <div className="home-address">Alameda Vicente Pinzon, 54, Vila Olímpia - 04547-130 São Paulo, SP</div>
                    </div>
                </div>
            </Style>
        );
    }
}

export default WebFooter;